import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Box, Typography } from "@mui/material";
import { usersTimeSheetColumns } from "./columns";
import { getAllTasks } from "../../../services/firabase/realtimeService";

const Dashboard = () => {
  const [data, setData] = useState([]);

  // ✅ Fetch user-task data from Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasks = await getAllTasks();
        setData(tasks);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        User Task Dashboard
      </Typography>

      <MaterialReactTable
        columns={usersTimeSheetColumns}
        data={data}
        enableGrouping
        enableColumnResizing
        enableSorting
        enableStickyHeader
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        initialState={{
          grouping: ["username"], // ✅ Group by User
          expanded: true,
          columnVisibility: {
            id: false, // ✅ Hide ID column
          },
          density: "compact",
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "12px",
            overflow: "hidden",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            padding: "8px",
            fontSize: "14px",
          },
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "75vh", // ✅ Limit table height
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: "#f3f3f3",
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default Dashboard;
