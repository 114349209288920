import { Typography } from "@mui/material";
import { LinearProgressWithLabel } from "../../../components/widgets/LinearProgressWithLabel";

export const usersTimeSheetColumns = [
  {
    id: "userColumn",
    header: "User",
    columns: [
      {
        accessorKey: "username",
        header: `User Name`,
        size: 300,
        Cell: ({ cell, row }) => {
          const isLatest = row.original.isLatest;

          return (
            <Typography
              sx={{
                backgroundColor: isLatest ? "#e3f2fd" : "transparent", // ✅ Highlight latest row
                color: isLatest ? "black" : "inherit",
                padding: "4px",
                borderRadius: "4px",
                fontWeight: isLatest ? "bold" : "normal",
              }}
            >
              {cell.getValue()}
            </Typography>
          );
        },
      },
    ],
  },
  {
    id: "taskDetailsColumn",
    header: "Tasks",
    columns: [
      {
        accessorKey: "date",
        header: `Date`,
        size: 100,
        filterVariant: "date",
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "time",
        header: `Time`,
        size: 100,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "taskName",
        header: `Task`,
        size: 250,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: "projectName",
        header: `Project`,
        size: 250,
        Cell: ({ cell }) => cell.getValue(),
      },
    ],
  },
];
