import {
  getDatabase,
  onChildAdded,
  ref,
  push,
  set,
  onValue,
  get,
  query,
  orderByChild,
  limitToLast,
} from "firebase/database";
import { realTimeDb } from "./firebaseConfig";

/**
 * Assign a task to a user and save it in Realtime Database.
 * @param {string} taskName - The name of the task.
 * @param {string} username - The username of the assignee.
 * @returns {Promise<void>}
 */
export const handleAssignTask = async (taskName, username, projectName) => {
  try {
    console.log("fireee", taskName, username, projectName);
    if (!username) throw new Error("Username is required");

    // Reference under the username in Realtime DB
    const assignmentRef = ref(realTimeDb, `task_assignments`);
    const newAssignmentRef = push(assignmentRef);

    await set(newAssignmentRef, {
      username,
      taskName,
      projectName,
      assignedAt: new Date().toISOString(),
    });

    console.log(`Task "${taskName}" assigned to user "${username}"`);
  } catch (error) {
    console.error("Error assigning task:", error);
    throw error;
  }
};

/**
 * Listen for new task assignments and execute a callback.
 * @param {Function} callback - Function to call when a new task assignment is added.
 * @returns {Function} - Function to unsubscribe from the listener.
 */
export const listenToTaskAssignments = (callback) => {
  try {
    const db = getDatabase();

    // Reference to the `task_assignments` node
    const taskAssignmentsRef = ref(db, "task_assignments");

    // Listen for new task assignments
    const unsubscribe = onChildAdded(taskAssignmentsRef, (snapshot) => {
      const task = snapshot.val();
      console.log("new assign ndddddd", snapshot.val());
      if (task) {
        // const userName = Object.keys(tasks)[1];
        // const changeId = Object.keys(tasks[userName])[0];
        const notification = {
          message: `${task.username} changed his current working task to  "${task.taskName} on project ${task.projectName}"`,
          time: new Date(task.assignedAt).toISOString(),
        };

        // ✅ Send browser notification
        if (Notification.permission === "granted") {
          new Notification("New Task Assigned", {
            body: notification.message,
            icon: "/favicon.ico",
          });
        }

        // ✅ Trigger callback with new notification
        callback(notification);

        // callback({
        //   message: `Task "${tasks.taskName}" is currntly working on task "${tasks.userName} on project ${tasks.projectName}"`,
        //   time: tasks.assignedAt,
        // });
      }
    });

    return unsubscribe;
  } catch (error) {
    console.error("Error listening to task assignments:", error);
    throw error;
  }
};
export const requestNotificationPermission = () => {
  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted");
      }
    });
  }
};

export const getAllTasks = async () => {
  const db = getDatabase();
  const taskAssignmentsRef = ref(db, "task_assignments");

  try {
    const snapshot = await get(
      query(taskAssignmentsRef, orderByChild("assignedAt"))
    );

    if (snapshot.exists()) {
      const rawData = snapshot.val();

      // ✅ Format data for table
      const formattedData = Object.values(rawData)
        .map((task) => ({
          id: task.taskId || "",
          username: task.username || "Unknown User",
          taskName: task.taskName || "Untitled Task",
          projectName: task.projectName || "Untitled Project",
          date: task.assignedAt
            ? new Date(task.assignedAt).toISOString().slice(0, 10)
            : "",
          time: task.assignedAt
            ? new Date(task.assignedAt).toLocaleTimeString()
            : "",
          assignedAt: task.assignedAt ? new Date(task.assignedAt) : null,
        }))
        .sort((a, b) => b.assignedAt - a.assignedAt); // ✅ Sort newest first

      // ✅ Group by user and get the last 10 tasks for each user
      const groupedData = {};
      formattedData.forEach((task) => {
        if (!groupedData[task.username]) {
          groupedData[task.username] = [];
        }
        if (groupedData[task.username].length < 10) {
          groupedData[task.username].push({
            ...task,
            isLatest: groupedData[task.username].length === 0, // ✅ First row for user = latest
          });
        }
      });

      // ✅ Flatten grouped data for table display
      return Object.values(groupedData).flat();
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching tasks:", error);
    throw error;
  }
};

// export const getAllTasks = async () => {
//   const db = getDatabase();
//   const taskAssignmentsRef = ref(db, "task_assignments");

//   try {
//     // ✅ Get last 10 tasks for each user using `orderByChild`
//     const snapshot = await get(
//       query(taskAssignmentsRef, orderByChild("assignedAt"))
//     );

//     if (snapshot.exists()) {
//       const rawData = snapshot.val();

//       // ✅ Format data for table
//       const formattedData = Object.values(rawData)
//         .map((task) => ({
//           id: task.taskId || "",
//           username: task.username || "Unknown User",
//           taskName: task.taskName || "Untitled Task",
//           projectName: task.projectName || "Untitled Project",
//           date: task.assignedAt
//             ? new Date(task.assignedAt).toISOString().slice(0, 10)
//             : "",
//         }))
//         // ✅ Sort by date (most recent first)
//         .sort((a, b) => new Date(b.date) - new Date(a.date));

//       // ✅ Group by user and get the last 10 tasks for each user
//       const groupedData = {};
//       formattedData.forEach((task) => {
//         if (!groupedData[task.username]) {
//           groupedData[task.username] = [];
//         }
//         if (groupedData[task.username].length < 10) {
//           groupedData[task.username].push(task);
//         }
//       });

//       // ✅ Flatten grouped data for table display
//       return Object.values(groupedData).flat();
//     } else {
//       return [];
//     }
//   } catch (error) {
//     console.error("Error fetching tasks:", error);
//     throw error;
//   }
// };
