// import Main2 from "../../pages/client/AllEquipments/PressureVessel";
import ManageStaff from "../../pages/admin/ManageStaff";

import AllTasksPage from "../../pages/admin/AllTasksPage";
import AllUsersTasksPage from "../../pages/admin/AllUserTaskPage";
import paths from "../../api/paths.api";
import AllTimesheetsPage from "../../pages/admin/AllTimesheetsPage";
import CurrentTasks from "../../pages/admin/CurrentTasks";
import DashboardPage from "../../pages/admin/DashboardPage";
import EmployeeDashboard from "../../pages/admin/DashboardPage/EmployeeDashboard";
import Project4d from "../../pages/admin/DashboardPage/4d/Project4d";
import ClientPage from "../../pages/admin/ClientsPage";

export const adminProtectedRoutes = [
  {
    path: "/allstaff",
    element: <ManageStaff />,
  },
  {
    path: paths.allTasks,
    element: <AllTasksPage />,
  },
  {
    path: paths.allUsersTimesheet,
    element: <AllUsersTasksPage />,
  },
  {
    path: paths.clients,
    element: <ClientPage />,
  },
  {
    path: paths.allTimesheets,
    element: <AllTimesheetsPage />,
  },
  {
    path: paths.dashboard,
    element: <Project4d />,
  },
  {
    path: paths.currentTasks,
    element: <CurrentTasks />,
  },
];
