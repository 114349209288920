import { da } from "date-fns/locale";
import axios from "../../../api/axios";

export const getAllTasks = async ({ accessToken }) => {
  const { data } = await axios.get("/task/all", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

export const getStatusDashboard = async ({ accessToken, userId }) => {
  const { data } = await axios.get("/task/statusDashboard/" + userId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};

export const getWorkloadDashboard = async ({ accessToken }) => {
  const { data } = await axios.get("/task/workloadDashboard", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};
export const getAllTimesheets = async ({ accessToken }) => {
  const { data } = await axios.get("/timesheet/all", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const validData = data.filter((d) => d.task_member && d.task_member !== "");
  console.log("timeeee", validData);
  return validData.reverse();
};
