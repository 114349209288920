import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Logo from "../../../images/chinook_blue-logo-only.png";
import {
  AccountCircle,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import {
  Badge,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../../context/authContext";
import {
  listenToTaskAssignments,
  requestNotificationPermission,
} from "../../../services/firabase/realtimeService";

const drawerWidth = 210;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "linear-gradient(45deg, #f3f1fe 0%, #d9e7ff 100%)",
  color: "Black",
  boxShadow: "none",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ResponsiveAppBar = ({ open, handleDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);

  const { accounts, instance } = useMsal();
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);

  // ✅ Request notification permission on component load
  React.useEffect(() => {
    console.log("roleeeeeeeee", auth.role);

    if (auth.role === "admin") {
      requestNotificationPermission();

      const unsubscribe = listenToTaskAssignments((newNotification) => {
        setNotifications((prev) => {
          // ✅ Keep only the last 10 notifications
          const updatedNotifications = [
            { ...newNotification, read: false },
            ...prev,
          ].slice(0, 10);
          return updatedNotifications;
        });
      });

      return () => unsubscribe();
    }
  }, [auth.role]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRequestPermission = async () => {
    const permission = await requestNotificationPermission();

    if (permission === "denied") {
      //   setShowDeniedWarning(true);
    }
  };
  const handleNotificationClick = async (event) => {
    setNotificationAnchorEl(event.currentTarget);
    await handleRequestPermission();

    setNotifications((prev) =>
      prev.map((notification) =>
        notification.read ? notification : { ...notification, read: true }
      )
    );
  };
  //   const handleNotificationClick = (event) => {
  //     setNotificationAnchorEl(event.currentTarget);

  //     setNotifications((prev) =>
  //       prev.map((notification) =>
  //         notification.read ? notification : { ...notification, read: true }
  //       )
  //     );
  //   };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNavigation = () => {
    navigate("/");
  };

  const logoutAndRedirect = () => {
    instance.logoutRedirect();
    navigate("/");
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar
        variant="dense"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box display="flex">
          {accounts?.length ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <img
            src={Logo}
            alt="ChinookDT"
            onClick={handleNavigation}
            style={{ height: "48px", cursor: "pointer" }}
          />
        </Box>

        <Box display="flex" alignItems="center">
          {auth?.role === "admin" && (
            <>
              <IconButton color="inherit" onClick={handleNotificationClick}>
                <Badge badgeContent={unreadCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>

              <Menu
                anchorEl={notificationAnchorEl}
                open={Boolean(notificationAnchorEl)}
                onClose={handleNotificationClose}
                PaperProps={{ style: { width: "320px" } }}
              >
                <Typography variant="h6" sx={{ padding: "8px 16px" }}>
                  Notifications
                </Typography>
                <Divider />
                <List>
                  {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <ListItem key={index} divider>
                        <ListItemText
                          primary={notification.message}
                          secondary={new Date(
                            notification.time
                          ).toLocaleString()}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No new notifications" />
                    </ListItem>
                  )}
                </List>
                <Divider />
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "8px",
                  }}
                >
                  <Link
                    component="button"
                    onClick={() => navigate("/admin/currentTasks")}
                  >
                    See All
                  </Link>
                </Box>
              </Menu>
            </>
          )}
          <IconButton onClick={handleMenu}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={logoutAndRedirect}>Logout</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;
