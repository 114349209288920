import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import FolderIcon from "@mui/icons-material/Folder";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

import { useNavigate } from "react-router-dom";
import { Collapse, List } from "@mui/material";
import { FC, useContext, useState } from "react";
import { adminList, ownerList, staffList } from "./utils";
import NavList from "./NavListItem";
import { AuthContext } from "../../../context/authContext";
import { ExpandLess } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  // backgroundColor: "#F3F4F6FF"
  background: "linear-gradient(225deg, #f3f1fe 0%, #d9e7ff 100%)",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  minHeight: "50px",
  // "& .MuiDrawer-docked": {
  //     backgroundColor: "red"
  // }
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideDrawer = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  const { auth } = useContext(AuthContext);
  const theme = useTheme();

  const [adminMenuOpen, setAdminMenuOpen] = useState(false);

  const handleClick = () => {
    setAdminMenuOpen(!adminMenuOpen);
  };

  return (
    <Drawer variant="permanent" open={open}>
      {open ? (
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
      ) : (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          // onClick={handleDrawerOpen}
          edge="start"
          sx={
            {
              // marginRight: 5,
              // ...(open && { display: 'none' }),
            }
          }
        >
          <MenuIcon />
        </IconButton>
      )}
      <Divider />
      <NavList open={open} items={staffList} />
      <Divider />
      {auth?.role === "admin" || auth?.role === "owner" ? (
        <>
          <List
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton onClick={handleClick}>
              <ListItemIcon sx={{ ml: 1 }}>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {open ? adminMenuOpen ? <ExpandLess /> : <ExpandMore /> : <></>}
            </ListItemButton>
          </List>
          <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
            <NavList open={open} items={adminList} />
          </Collapse>
        </>
      ) : (
        <></>
      )}
      <Divider />
      {auth?.role === "owner" ? (
        <NavList open={open} items={ownerList} />
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default SideDrawer;
