import { useContext } from "react";
import axios from "../../../../api/axios";
import { tokenHeader } from "../../../../api/tokenHeader";

export const createProject = async ({ values, accessToken }) => {
  return await axios.post("/project/create", values, tokenHeader(accessToken));
};

export const getAllProjects = async ({ accessToken }) => {
  const { data } = await axios.get("/project/all", tokenHeader(accessToken));
  const modifiedRes = data
    .map((res) => ({
      ...res,
      members: res["project_members"],
    }))
    .filter((p) => !p.isArchived);
  console.log("projjjss", modifiedRes);
  return modifiedRes;
};

export const getOneProject = async ({ projectId }) => {};

export const updateProject = async ({ values, accessToken }) => {
  console.log("updating projects ", values, accessToken);

  return await axios.put("/project/update", values, tokenHeader(accessToken));
};

export const archiveProject = async ({ id, accessToken }) => {
  console.log("archivin: ", id);
  return await axios.put(
    "/project/archive/" + id,
    id,
    tokenHeader(accessToken)
  );
};

export const deleteProject = async ({ projectId }) => {};
