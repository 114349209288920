import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import {
  getAllTasks,
  getStatusDashboard,
  getWorkloadDashboard,
  getAllTimesheets,
} from "./api";
import { useQuery } from "@tanstack/react-query";
import { MaterialReactTable } from "material-react-table";
import { usersTimeSheetColumns } from "./columns";
import { tableDesignProps } from "../../../styles/muiProps";
import TableChartIcon from "@mui/icons-material/TableChart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ChangeViewTabs from "../../../components/Tabs/ChangeViewTabs";
import TableView from "./TableView";
import ChartView from "./ChartView";

const AllUsersTimesheet = () => {
  const { accessToken } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    isSuccess,
    isLoading,
    isError,
    data: allTasks,
  } = useQuery({
    queryKey: ["tasks_all"],
    queryFn: () => getAllTasks({ accessToken }),
  });
  const {
    isSuccess: isTimesheetSuccess,
    isLoading: isTimesheetLoading,
    isError: isTimesheetError,
    data: allUsersTimesheet,
  } = useQuery({
    queryKey: ["users_timesheet_all"],
    queryFn: () => getAllTimesheets({ accessToken }),
  });
  if (isTimesheetSuccess) {
    // console.log("tttttttttttttttt", allUsersTimesheet);
  }

  const { data: statusDasboardData } = useQuery({
    queryKey: ["status_dashboard"],
    queryFn: () => getStatusDashboard({ accessToken }),
  });

  const { data: workloadDashboardData } = useQuery({
    queryKey: ["workload_dashboard"],
    queryFn: () => getWorkloadDashboard({ accessToken }),
  });

  if (statusDasboardData) {
    console.log({ statusDasboardData });
  }
  if (workloadDashboardData) {
    console.log({ workloadDashboardData });
  }

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <Box sx={{ m: 4, width: "100%", pr: 3 }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" fontSize={"1.5rem"} color="secondary">
            All Users Timesheet
          </Typography>

          <div></div>
        </div>

        <TableView columns={usersTimeSheetColumns} data={allUsersTimesheet} />
      </Box>
    </Box>
  );
};

export default AllUsersTimesheet;
