import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PunchClockOutlinedIcon from "@mui/icons-material/PunchClock";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { paths as projectPaths } from "../../../../pages/ProjectsPage/projectsRoutes";
// import { paths as taskPaths } from "../../../../pages/TasksPage/taskRoutes";
import { ReactElement } from "react";
import { Badge, SvgIconTypeMap } from "@mui/material";
import People from "@mui/icons-material/People";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import paths from "../../../api/paths.api";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

export const staffList = [
  {
    label: "Projects",
    icon: <FolderOutlinedIcon />,
    path: paths.myProjects,
  },
  {
    label: "My Tasks",
    icon: <AssignmentOutlinedIcon />,
    path: paths.myTasks,
  },
  // Add more items as needed
];

export const adminList = [
  {
    label: "Dashboard",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <DashboardOutlinedIcon />
      </Badge>
    ),
    path: paths.dashboard,
  },
  {
    label: "Clients",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <DashboardOutlinedIcon />
      </Badge>
    ),
    path: paths.clients,
  },
  {
    label: "All Tasks",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <AssignmentOutlinedIcon />
      </Badge>
    ),
    path: paths.allTasks,
  },
  {
    label: "All Users",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PeopleAltIcon />
      </Badge>
    ),
    path: paths.allUsersTimesheet,
  },
  {
    label: "All Timesheets",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PunchClockOutlinedIcon />
      </Badge>
    ),
    path: paths.allTimesheets,
  },
  {
    label: "Current Tasks",
    icon: (
      <Badge
        color="warning"
        variant="dot"
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <PunchClockOutlinedIcon />
      </Badge>
    ),
    path: paths.currentTasks,
  },
];

export const ownerList = [
  {
    label: "Members",
    icon: <PeopleAltIcon />,
    path: paths.allstaff,
  },
];
