import axios from "../../../api/axios";

export const getAllTimesheets = async ({ accessToken }) => {
  const { data } = await axios.get("/timesheet/all", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};
